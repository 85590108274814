/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { toast } from "react-toastify";

import copy from "../../assets/img/Copy.svg";
import { Text } from "../../utils/Text";
import "./index.scss";

export const CardHashPopup = ({ roundHash }) => {
  const [isOpenHashPopup, setOpenHashPopup] = useState(false);
  const { t } = useTranslation();

  const handleCopyClick = () => {
    navigator.clipboard.writeText(roundHash).then(() => {
      toast.success(`${t("MAIN.MAIN.COPY")}`, {
        autoClose: 5000,
        className: "toast-message",
      });
    });
  };

  const handleOpen = () => setOpenHashPopup(true);
  const handleClose = () => setOpenHashPopup(false);

  const handleBackgroundClick = (event) => {
    if (event.target.classList.contains("bg")) handleClose();
  };

  return (
    <>
      <div className="BetCardsGame__FairPlay">
        <span className="BetCardsGame__FairPlay__title" onClick={handleOpen}>
          <Text tid="CLASSIC.CLASSIC_TAB.OPEN_HASH" />
        </span>
        <span className="BetCardsGame__FairPlay__text" onClick={handleOpen}>
          {roundHash}
        </span>
      </div>
      {isOpenHashPopup ? (
        <div
          className="bg hashContainer"
          style={{ opacity: 1, zIndex: 9999 }}
          onClick={handleBackgroundClick}
        >
          <div
            className="bidPopUp"
            style={{
              opacity: 1,
              zIndex: 9999,
              background: "rgb(32, 32, 32)",
              padding: "18px 12px 12px 12px",
              maxWidth: "600px",
              width: "80%",
            }}
          >
            <div className="bidPopUp__container" style={{ height: "auto" }}>
              <div
                style={{
                  textAlign: "center",
                  marginBottom: 12,
                  color: "#EAEAEA",
                }}
                className="bidPopUp__hash-title"
              >
                <Text tid="CLASSIC.CLASSIC_TAB.HASH_TITLE" />
              </div>
              <hr className="bidPopUp__header-line" style={{ opacity: 0.25 }} />
              <br />
              <div
                onClick={handleCopyClick}
                style={{
                  backgroundColor: "#262626",
                  wordBreak: "break-all",
                  textAlign: "center",
                  fontWeight: "400",
                  cursor: "pointer",
                  marginBottom: 12,
                  color: "#EAEAEA",
                  borderRadius: 8,
                  fontSize: 14,
                  padding: 6,
                }}
              >
                {roundHash}
                &nbsp;
                <img
                  style={{ width: 14, height: 14, marginBottom: -2 }}
                  src={copy}
                  alt=""
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                  color: "#CACACA",
                  fontWeight: 400,
                  fontSize: 13,
                  lineHeight: "20px",
                }}
              >
                <div style={{ fontWeight: 500, color: "#EEEEEE" }}>
                  <Text tid="CLASSIC.CLASSIC_TAB.HASH_T_1" />
                </div>
                <Text tid="CLASSIC.CLASSIC_TAB.HASH_T_2" />
                <br />
                <br />
                <div>
                  <Text tid="CLASSIC.CLASSIC_TAB.HASH_T_3" />
                </div>
                <br />
                <Text tid="CLASSIC.CLASSIC_TAB.HASH_T_4" />
                <br />
                <br />
                <Text tid="CLASSIC.CLASSIC_TAB.HASH_T_5" />
                <br />
                <Text tid="CLASSIC.CLASSIC_TAB.HASH_T_6" />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
