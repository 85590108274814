import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SettingsUpdatePasswordView } from "./View";

import {
  resetUpdatePassword,
  updatePassword,
} from "../../actions/settingsUpdatePassword";
import { sendCode, resetSendCode } from "../../actions/settingsSendCode";
import { SETTINGS } from "../../constants/fields";
import {
  getData,
  getErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../utils/store";

export const SettingsUpdatePassword = () => {
  const captchaRef = useRef();

  const [isCaptchaSuccess, setIsCaptchaSuccess] = useState(false);

  const {
    isUpdatePasswordLoading,
    isUpdatePasswordSuccess,
    isUpdatePasswordError,
    updatePasswordErrorMessage,
    isSendCodeLoading,
    isSendCodeSuccess,
    isSendCodeError,
    sendCodeErrorMessage,
    sendCodeData,
  } = useSelector(({ settingsUpdatePassword, settingsSendCode }) => ({
    isUpdatePasswordLoading: isRequestPending(settingsUpdatePassword.request),
    isUpdatePasswordSuccess: isRequestSuccess(settingsUpdatePassword.request),
    isUpdatePasswordError: isRequestError(settingsUpdatePassword.request),
    updatePasswordErrorMessage: getErrorMessage(settingsUpdatePassword.request),
    isSendCodeLoading: isRequestPending(settingsSendCode.request),
    isSendCodeSuccess: isRequestSuccess(settingsSendCode.request),
    isSendCodeError: isRequestError(settingsSendCode.request),
    sendCodeData: getData(settingsSendCode.request),
    sendCodeErrorMessage: getErrorMessage(settingsSendCode.request),
  }));

  useEffect(() => {
    if (sendCodeErrorMessage === "CAPTCHA_NOT_VALID") {
      captchaRef.current.reset();

      setIsCaptchaSuccess(false);
    }
  }, [sendCodeErrorMessage]);

  const dispatch = useDispatch();

  const defaultFields = {
    currentPassword: "",
    newPassword: "",
    repeatNewPassword: "",
  };

  const [fields, setFields] = useState(defaultFields);

  const [fieldsVisibility, setFieldsVisiblity] = useState({
    currentPassword: false,
    newPassword: false,
    repeatNewPassword: false,
  });

  useEffect(() => {
    return () => {
      dispatch(resetUpdatePassword());
      dispatch(resetSendCode());
    };
  }, []);

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const changeFieldsVisibility = (field) => {
    setFieldsVisiblity({
      ...fieldsVisibility,
      [field]: !fieldsVisibility[field],
    });
  };

  const onSubmit = async ({ code }) => {
    const { success } = await dispatch(
      updatePassword({
        [SETTINGS.PASSWORD]: fields.currentPassword,
        [SETTINGS.NEW_PASSWORD]: fields.newPassword,
        [SETTINGS.CONFIRM_CODE]: code,
      })
    );

    if (success) {
      setFields(defaultFields);
      dispatch(resetSendCode());
    }
  };

  const submitSendCode = async (e) => {
    e.preventDefault();

    const token = captchaRef.current.getValue();

    await dispatch(
      sendCode({ [SETTINGS.TYPE]: "password", [SETTINGS.CAPTCHA]: token })
    );
  };

  const isFormDisabled =
    !fields.currentPassword ||
    !fields.newPassword ||
    !fields.repeatNewPassword ||
    fields.newPassword !== fields.repeatNewPassword ||
    !isCaptchaSuccess;

  return (
    <SettingsUpdatePasswordView
      fields={fields}
      changeField={changeField}
      fieldsVisibility={fieldsVisibility}
      changeFieldsVisibility={changeFieldsVisibility}
      disabled={isFormDisabled}
      submitSendCode={submitSendCode}
      onSubmit={onSubmit}
      loading={isSendCodeLoading}
      error={isSendCodeError}
      errorMessage={sendCodeErrorMessage}
      success={isSendCodeSuccess}
      isUpdatePasswordSuccess={isUpdatePasswordSuccess}
      isUpdatePasswordLoading={isUpdatePasswordLoading}
      isUpdatePasswordError={isUpdatePasswordError}
      updatePasswordErrorMessage={updatePasswordErrorMessage}
      sendCodeData={sendCodeData}
      captchaRef={captchaRef}
      setIsCaptchaSuccess={setIsCaptchaSuccess}
    />
  );
};
