import React from "react";
import "./index.scss";
import TopOf from "../TopOf";
import HeaderTop from "../HeaderTop";
import TopWinners from "../../containers/TopWinners/index";
import { withProvider } from "../../utils";

const TopTab = () => {
  return (
    <div className={`body ${withProvider() && "provider"}`}>
      <HeaderTop />
      <TopOf />
      <TopWinners />
    </div>
  );
};

export default TopTab;
