import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Recaptcha from "react-google-recaptcha";

import "./index.scss";

import { Text } from "../../utils/Text";
import { ROUTES } from "../../constants/routes";
import { RECOVERY_ACCOUNT_RESET } from "../../constants/fields";
import { AlertMessage } from "../../components/AlertMessage";
import PrimaryButton from "../../components/PrimaryButton";

export const RecoveryAccountResetView = ({
  fields,
  changeField,
  onSubmit,
  isFormDisabled,
  loading,
  error,
  errorMessage,
  captchaRef,
  setIsCaptchaSuccess,
}) => {
  const { t } = useTranslation();

  const captchaSize = window.matchMedia("(max-width: 400px)").matches
    ? "compact"
    : "normal";

  return (
    <form className="LogIn" onSubmit={onSubmit}>
      <h1 className="LogIn__title">
        <Text tid="AUTH.AUTH.REPASSWORD" />
      </h1>
      <div className="LogIn__formBox">
        <div className="LogIn__form">
          <div className="LogIn__inputBox">
            <span className="LogIn__inputTitle">
              <Text tid="MAIN.MAIN.EMAIL" />
            </span>
            <input
              className="LogIn__input"
              type="email"
              placeholder={t("AUTH.AUTH.ENTER_EMAIL")}
              value={fields[RECOVERY_ACCOUNT_RESET.EMAIL]}
              onChange={(e) =>
                changeField(RECOVERY_ACCOUNT_RESET.EMAIL, e.target.value)
              }
              required
              minLength={4}
              maxLength={30}
            />
          </div>
          <Recaptcha
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            ref={captchaRef}
            size={captchaSize}
            style={{ margin: "0 auto", maxWidth: "100%" }}
            theme="dark"
            onChange={() => setIsCaptchaSuccess(true)}
          />
          <PrimaryButton
            className="LogIn__button"
            type="submit"
            disabled={isFormDisabled}
            loading={loading}
          >
            <span>
              <Text tid="AUTH.AUTH.NEXT" />
            </span>
          </PrimaryButton>

          {error && <AlertMessage message={errorMessage} type="error" />}

          <div className="LogIn__registration">
            <Link to={ROUTES.LOGIN}>
              <span className="LogIn__registrationTitle">
                <Text tid="AUTH.AUTH.RESET_PASSWORD" />{" "}
                <span className="LogIn__registrationLink">
                  <Text tid="AUTH.AUTH.LOGIN" />
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};
