import React from "react";
import { useTranslation } from "react-i18next";
import Recaptcha from "react-google-recaptcha";

import "./index.scss";
import eye from "../../assets/img/Sign in.svg";

import { Text } from "../../utils/Text";
import { SETTINGS } from "../../constants/fields";
import PrimaryButton from "../../components/PrimaryButton";
import { AlertMessage } from "../../components/AlertMessage";
import { SettingsConfirmCode } from "../settings-confirm-code";

export const SettingsUpdatePasswordView = ({
  fields,
  changeField,
  fieldsVisibility,
  changeFieldsVisibility,
  disabled,
  onSubmit,
  submitSendCode,
  loading,
  error,
  errorMessage,
  success,
  isUpdatePasswordSuccess,
  isUpdatePasswordLoading,
  isUpdatePasswordError,
  updatePasswordErrorMessage,
  sendCodeData,
  captchaRef,
  setIsCaptchaSuccess,
}) => {
  const { t } = useTranslation();

  const captchaSize = window.matchMedia("(max-width: 400px)").matches
    ? "compact"
    : "normal";

  return (
    <React.Fragment>
      <div>
        <p className="settings-security__password-box-title">
          <Text tid="SETTINGS.SETTINGS_TAB.PASSWORD" />
        </p>
        {success && sendCodeData.type === "password" ? (
          <SettingsConfirmCode
            onSubmit={onSubmit}
            isLoading={isUpdatePasswordLoading}
            isError={isUpdatePasswordError}
            errorMessage={updatePasswordErrorMessage}
          />
        ) : (
          <form
            onSubmit={submitSendCode}
            className="settings-security__password-box"
          >
            <div>
              <p className="settings-security__password-box-text text">
                <Text tid="SETTINGS.SETTINGS_TAB.OLD_PASSWORD" />
              </p>
              <div className="settings-security__password-box-input-wrapper">
                <input
                  className="settings-security__password-box-input input"
                  type={
                    fieldsVisibility[SETTINGS.CURRENT_PASSWORD]
                      ? "text"
                      : "password"
                  }
                  placeholder={t("SETTINGS.SETTINGS_TAB.ENTER_OLD_PASSWORD")}
                  value={fields[SETTINGS.CURRENT_PASSWORD]}
                  onChange={(e) =>
                    changeField(SETTINGS.CURRENT_PASSWORD, e.target.value)
                  }
                  required
                  minLength={4}
                  maxLength={30}
                />
                <img
                  style={{
                    position: "absolute",
                    top: "25%",
                    right: "12px",
                    cursor: "pointer",
                  }}
                  src={eye}
                  onClick={() =>
                    changeFieldsVisibility(SETTINGS.CURRENT_PASSWORD)
                  }
                  alt="Toggle Password Visibility"
                  className="settings-security__password-box-input-icon-image"
                />
              </div>
            </div>
            <div>
              <p className="settings-security__password-box-text text">
                <Text tid="SETTINGS.SETTINGS_TAB.NEW_PASSWORD" />
              </p>
              <div className="settings-security__password-box-input-wrapper">
                <input
                  className="settings-security__password-box-input input"
                  type={
                    fieldsVisibility[SETTINGS.NEW_PASSWORD]
                      ? "text"
                      : "password"
                  }
                  placeholder={t("SETTINGS.SETTINGS_TAB.ENTER_NEW_PASSWORD")}
                  value={fields[SETTINGS.NEW_PASSWORD]}
                  onChange={(e) =>
                    changeField(SETTINGS.NEW_PASSWORD, e.target.value)
                  }
                  required
                  minLength={4}
                  maxLength={30}
                />
                <img
                  style={{
                    position: "absolute",
                    top: "25%",
                    right: "12px",
                    cursor: "pointer",
                  }}
                  src={eye}
                  onClick={() => changeFieldsVisibility(SETTINGS.NEW_PASSWORD)}
                  alt="Toggle Password Visibility"
                  className="settings-security__password-box-input-icon-image"
                />
              </div>
            </div>
            <div>
              <p className="settings-security__password-box-text text">
                <Text tid="SETTINGS.SETTINGS_TAB.CONFIRM_NEW_PASSWORD" />
              </p>
              <div className="settings-security__password-box-input-wrapper">
                <input
                  className="settings-security__password-box-input input"
                  type={
                    fieldsVisibility[SETTINGS.REPEAT_NEW_PASSWORD]
                      ? "text"
                      : "password"
                  }
                  placeholder={t(
                    "SETTINGS.SETTINGS_TAB.ENTER_CONFIRM_NEW_PASSWORD"
                  )}
                  value={fields[SETTINGS.REPEAT_NEW_PASSWORD]}
                  onChange={(e) =>
                    changeField(SETTINGS.REPEAT_NEW_PASSWORD, e.target.value)
                  }
                  required
                  minLength={4}
                  maxLength={30}
                />
                <img
                  style={{
                    position: "absolute",
                    top: "25%",
                    right: "12px",
                    cursor: "pointer",
                  }}
                  src={eye}
                  onClick={() =>
                    changeFieldsVisibility(SETTINGS.REPEAT_NEW_PASSWORD)
                  }
                  alt="Toggle Password Visibility"
                  className="settings-security__password-box-input-icon-image"
                />
              </div>
            </div>
            <Recaptcha
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              ref={captchaRef}
              size={captchaSize}
              style={{ margin: "0 auto", maxWidth: "100%" }}
              theme="dark"
              onChange={() => setIsCaptchaSuccess(true)}
            />
            <PrimaryButton
              type="submit"
              disabled={disabled}
              loading={loading && sendCodeData.type === "password"}
              // className="settings-security__password-box-button button"
            >
              <span>
                <Text tid="MAIN.MAIN.SAVE" />
              </span>
            </PrimaryButton>

            {error && sendCodeData.type === "password" && (
              <AlertMessage message={errorMessage} type="error" />
            )}
            {isUpdatePasswordSuccess && (
              <AlertMessage
                message="SETTINGS.SETTINGS_TAB.UPDATE_PASSWORD_SUCCESS"
                type="success"
              />
            )}
          </form>
        )}
      </div>
    </React.Fragment>
  );
};
