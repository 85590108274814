import "./index.scss";
import React from "react";
import moment from "moment";

import { Text } from "../../utils/Text";
import { fix2 } from "../../utils/fixers";
import Loader from "../../components/Loader";
import { UserProfile } from "../../components/UserProfile";

const History = ({ game, handleCopyClick }) => {
  if (game?.lenght) return <Loader />;

  const winner = game.members.find((member) => member?._id === game.winner);
  const roundNumber = game?.roundNumber;
  const hash = game.roundHash;

  const date = moment(game.createdAt);

  return (
    <div className="history-page">
      <div className="history-page__container">
        <div className="history-page__user">
          <div className="history-page__user-avatar">
            <UserProfile user={winner}>
              <img src={winner?.photo} alt="avatar" />
            </UserProfile>
          </div>
          <div className="history-page__user-info">
            <div className="history-page__user-text">{winner?.nickname}</div>
            <div className="history-page__user-text">
              <Text tid="MAIN.MAIN.WIN" />:
              <p className="history-page__user-text--highlight">
                {fix2(game.totalBank)}$
              </p>
            </div>
            <div className="history-page__user-text">
              <Text tid="MAIN.MAIN.CHANCE" />:
              <p className="history-page__user-text--highlight">
                {(winner?.chance || 0)?.toFixed?.(2)}%
              </p>
            </div>
          </div>
        </div>
        <div className="history-page__hash">
          <div className="history-page__hash-text">
            <Text tid="MAIN.MAIN.ROUND_HASH" />:
            <p
              className="history-page__hash-text--highlight history-page__hash-text-copied "
              onClick={() => {
                handleCopyClick(hash);
              }}
            >
              {hash}
            </p>
          </div>
          <div className="history-page__hash-text">
            <Text tid="CLASSIC.CLASSIC_TAB.RANDOM_NUMBER" />:
            <p className="history-page__hash-text--highlight">{roundNumber}</p>
          </div>
        </div>
        <div className="history-page__game">
          <div className="history-page__game-id_p">
            <p>
              <Text tid="MAIN.MAIN.GAME_NUMBER" /> #{game.gameId}
            </p>
          </div>
          <div
            className="history-page__game-time"
            style={{ fontSize: 12, float: "right" }}
          >
            {date.isSame(moment(), "day") && (
              <p>
                {" "}
                <Text tid="CLASSIC.CLASSIC_TAB.TODAY_AT" />{" "}
                {date.format("HH:mm")}
              </p>
            )}
            {date.isSame(moment().subtract(1, "days"), "day") && (
              <p>
                {" "}
                <Text tid="CLASSIC.CLASSIC_TAB.YESTERDAY_IN" />{" "}
                {date.format("HH:mm")}
              </p>
            )}
            {!date.isSame(moment(), "day") &&
              !date.isSame(moment().subtract(1, "days"), "day") && (
                <p>
                  {date.year() === moment().year() ? (
                    <p>
                      {" "}
                      {date.format("DD.MM")}{" "}
                      <Text tid="CLASSIC.CLASSIC_TAB.AT" />{" "}
                      {date.format("HH:mm")}
                    </p>
                  ) : (
                    <p>
                      {" "}
                      {date.format("DD.MM.YYYY")}{" "}
                      <Text tid="CLASSIC.CLASSIC_TAB.AT" />{" "}
                      {date.format("HH:mm")}
                    </p>
                  )}
                </p>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
