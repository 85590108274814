import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { AuthRoute, NonAuthRoute } from "./route";
import { RecoveryAccountReset } from "../recovery-account-reset";
import { RecoveryAccountConfirm } from "../recovery-account-confirm";
import { RecoveryAccountUpdate } from "../recovery-account-update";
import { ROUTES } from "../../constants/routes";
import { LogIn } from "../login";
import { SignUp } from "../signup";
import Header from "../Header/index";
import Wallet from "../Wallet/index";
import WalletSettings from "../WalletSettings/index";
import BidPopUp from "../BidPopUp/index";
import BurgerPopUp from "../BurgerPopUp/index";
import Airdrop from "../Airdrop/index";
import { PageLayout, AuthorizationLayout } from "./layouts";
import Best from "../../components/Best";
import HistoryTab from "../../components/HistoryTab";
import TopTab from "../../components/TopTab";
import ClassicGame from "../ClassicGame";
import DoubleGame from "../../components/DoubleGame";
import TopTabDouble from "../../components/TopTabDouble";
import HistoryTabDouble from "../../components/HistoryTabDouble";
import { SettingsNavigation } from "../settings-navigation";
import { SettingsLayout } from "./layouts/settings";
import { SettingsUpdatePassword } from "../settings-update-password";
import { SettingsUpdateEmail } from "../settings-update-email";
import { SettingsUpdateNickname } from "../settings-update-nickname";
import { SettingsLogout } from "../settings-logout";
import { SignupConfirmCode } from "../signup-confirm-code";
import { SettingsReferralLink } from "../settings-referral-link";
import { TermsOfConditions } from "../TermsOfConditions";
import { SettingsPartner } from "../settings-partner";
import { Policy } from "../Policy";
import { Faq } from "../FAQ";
import { PaymentList } from "../payment-list";
import { SettingsUpdatePhoto } from "../settings-update-photo";
import CardsGame from "../../components/CardsGame";
import CrashGame from "../../components/CrashGame";
import { withProvider } from "../../utils";

export const AppRoutes = () => {
  const [opacityWallet, setOpacityWallet] = useState(0);
  const [walletActiveTab, setWalletActiveTab] = useState("deposit");
  const [opacityWalletSettings, setOpacityWalletSettings] = useState(0);
  const [opacityBidPopUp, setOpacityBidPopUp] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [isChatOpened, setIsChatOpened] = useState(false);

  const handleBidPopUpClick = () => {
    setOpacityBidPopUp(1);
  };

  const handleWalletClick = () => {
    setOpacityWallet(1);
  };

  const handleWalletSettingsClick = () => {
    setOpacityWalletSettings(1);
  };

  return (
    <Router>
      <div>
        <Header
          isActive={isActive}
          setIsActive={setIsActive}
          handleWalletClick={handleWalletClick}
          setOpacity={setOpacityWallet}
          handleWalletSettingsClick={handleWalletSettingsClick}
        />
      </div>
      <Routes>
        <Route
          path="/faq"
          element={
            <PageLayout
              isChatOpened={isChatOpened}
              setIsChatOpened={setIsChatOpened}
            >
              <div className="App-Body">
                <Faq />
              </div>
            </PageLayout>
          }
        />
        <Route
          path="/policy"
          element={
            <PageLayout
              isChatOpened={isChatOpened}
              setIsChatOpened={setIsChatOpened}
            >
              <div className="App-Body">
                <Policy />
              </div>
            </PageLayout>
          }
        />
        <Route
          path="/terms-of-conditions"
          element={
            <PageLayout
              isChatOpened={isChatOpened}
              setIsChatOpened={setIsChatOpened}
            >
              <div className="App-Body">
                <TermsOfConditions />
              </div>
            </PageLayout>
          }
        />
        <Route
          path="/"
          element={
            <PageLayout
              isChatOpened={isChatOpened}
              setIsChatOpened={setIsChatOpened}
            >
              <div className="App-Body">
                <ClassicGame
                  handleBidPopUpClick={handleBidPopUpClick}
                  setCurrency={setCurrency}
                  currency={currency}
                  isChatOpened={isChatOpened}
                  setIsChatOpened={setIsChatOpened}
                />
              </div>
              <div className="App-Best">
                <Best />
              </div>
            </PageLayout>
          }
        />

        <Route
          path="/classic/history"
          element={
            <PageLayout
              isChatOpened={isChatOpened}
              setIsChatOpened={setIsChatOpened}
            >
              <div className="App-Body">
                <HistoryTab handleBidPopUpClick={handleBidPopUpClick} />
              </div>
            </PageLayout>
          }
        />
        <Route
          path="/classic/top"
          element={
            <PageLayout
              isChatOpened={isChatOpened}
              setIsChatOpened={setIsChatOpened}
            >
              <div className="App-Body">
                <TopTab handleBidPopUpClick={handleBidPopUpClick} />
              </div>
            </PageLayout>
          }
        />
        <Route
          path="/double"
          element={
            <PageLayout
              isChatOpened={isChatOpened}
              setIsChatOpened={setIsChatOpened}
            >
              <div className="App-Body">
                <DoubleGame handleBidPopUpClick={handleBidPopUpClick} />
              </div>
            </PageLayout>
          }
        />
        <Route
          path="/double/history"
          element={
            <PageLayout
              isChatOpened={isChatOpened}
              setIsChatOpened={setIsChatOpened}
            >
              <div className="App-Body">
                <HistoryTabDouble handleBidPopUpClick={handleBidPopUpClick} />
              </div>
            </PageLayout>
          }
        />
        <Route
          path="/double/top"
          element={
            <PageLayout
              isChatOpened={isChatOpened}
              setIsChatOpened={setIsChatOpened}
            >
              <div className="App-Body">
                <TopTabDouble handleBidPopUpClick={handleBidPopUpClick} />
              </div>
            </PageLayout>
          }
        />
        <Route
          path="/cards"
          element={
            <PageLayout
              isChatOpened={isChatOpened}
              setIsChatOpened={setIsChatOpened}
            >
              <div className="App-Body">
                <CardsGame
                  handleBidPopUpClick={handleBidPopUpClick}
                  isChatOpened={isChatOpened}
                  setCurrency={setCurrency}
                  currency={currency}
                />
              </div>
            </PageLayout>
          }
        />
        <Route
          path="/crash"
          element={
            <PageLayout
              isChatOpened={isChatOpened}
              setIsChatOpened={setIsChatOpened}
            >
              <div className="App-Body">
                <CrashGame
                  handleBidPopUpClick={handleBidPopUpClick}
                  isChatOpened={isChatOpened}
                  setCurrency={setCurrency}
                  currency={currency}
                />
              </div>
            </PageLayout>
          }
        />
        <Route element={<AuthRoute />}>
          <Route
            path="/airdrop/*"
            element={
              <PageLayout
                isChatOpened={isChatOpened}
                setIsChatOpened={setIsChatOpened}
              >
                <div className="App-Body">
                  <Airdrop />
                </div>
              </PageLayout>
            }
          />
        </Route>
        <Route element={<NonAuthRoute />}>
          <Route
            path={ROUTES.LOGIN}
            element={
              <PageLayout
                isChatOpened={isChatOpened}
                setIsChatOpened={setIsChatOpened}
              >
                <div className="App-Body">
                  <AuthorizationLayout>
                    <LogIn />
                  </AuthorizationLayout>
                </div>
              </PageLayout>
            }
          />
          <Route
            path={ROUTES.SIGNUP}
            element={
              <PageLayout
                isChatOpened={isChatOpened}
                setIsChatOpened={setIsChatOpened}
              >
                <div className="App-Body">
                  <AuthorizationLayout>
                    <SignUp />
                  </AuthorizationLayout>
                </div>
              </PageLayout>
            }
          />
          <Route
            path={ROUTES.SIGNUP_CONFIRM()}
            element={
              <PageLayout
                isChatOpened={isChatOpened}
                setIsChatOpened={setIsChatOpened}
              >
                <div className="App-Body">
                  <AuthorizationLayout>
                    <SignupConfirmCode />
                  </AuthorizationLayout>
                </div>
              </PageLayout>
            }
          />
          <Route
            path={ROUTES.RECOVERY_ACCOUNT_RESET}
            element={
              <PageLayout
                isChatOpened={isChatOpened}
                setIsChatOpened={setIsChatOpened}
              >
                <div className="App-Body">
                  <AuthorizationLayout>
                    <RecoveryAccountReset />
                  </AuthorizationLayout>
                </div>
              </PageLayout>
            }
          />
          <Route
            path={ROUTES.RECOVERY_ACCOUNT_CONFIRM}
            element={
              <PageLayout
                isChatOpened={isChatOpened}
                setIsChatOpened={setIsChatOpened}
              >
                <div className="App-Body">
                  <AuthorizationLayout>
                    <RecoveryAccountConfirm />
                  </AuthorizationLayout>
                </div>
              </PageLayout>
            }
          />
          <Route
            path={ROUTES.RECOVERY_ACCOUNT_UPDATE}
            element={
              <PageLayout
                isChatOpened={isChatOpened}
                setIsChatOpened={setIsChatOpened}
              >
                <div className="App-Body">
                  <AuthorizationLayout>
                    <RecoveryAccountUpdate />
                  </AuthorizationLayout>
                </div>
              </PageLayout>
            }
          />
        </Route>
        <Route element={<AuthRoute />}>
          <Route
            path="/settings/*"
            element={
              <PageLayout
                isChatOpened={isChatOpened}
                setIsChatOpened={setIsChatOpened}
              >
                <div className="App-Body-Settings">
                  <SettingsNavigation />
                  <Routes>
                    <Route
                      path="/general"
                      element={
                        <SettingsLayout title="GENERAL">
                          <SettingsUpdatePhoto />
                          <SettingsUpdateNickname />
                          <SettingsLogout />
                        </SettingsLayout>
                      }
                    />
                    <Route
                      path="/security"
                      element={
                        <SettingsLayout title="SECURITY">
                          <SettingsUpdatePassword />
                          <SettingsUpdateEmail />
                        </SettingsLayout>
                      }
                    />
                    <Route
                      path="/referral"
                      element={
                        <SettingsLayout title="REFERRAL">
                          <SettingsReferralLink />
                        </SettingsLayout>
                      }
                    />
                    <Route
                      path="/partner"
                      element={
                        <SettingsLayout title="PARTNER">
                          <SettingsPartner />
                        </SettingsLayout>
                      }
                    />
                    <Route
                      path="/deposit"
                      element={
                        <SettingsLayout title="DEPOSIT">
                          <PaymentList
                            handleWalletClick={handleWalletClick}
                            setWalletActiveTab={setWalletActiveTab}
                            type="DEPOSIT"
                          />
                        </SettingsLayout>
                      }
                    />
                    <Route
                      path="/withdraw"
                      element={
                        <SettingsLayout title="WITHDRAW">
                          <PaymentList
                            handleWalletClick={handleWalletClick}
                            setWalletActiveTab={setWalletActiveTab}
                            type="WITHDRAW"
                          />
                        </SettingsLayout>
                      }
                    />
                  </Routes>
                </div>
              </PageLayout>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
      </Routes>
      {!withProvider() && (
        <React.Fragment>
          <Wallet
            opacity={opacityWallet}
            setOpacity={setOpacityWallet}
            activeTab={walletActiveTab}
            setActiveTab={setWalletActiveTab}
          />
          <WalletSettings
            opacityWalletSettings={opacityWalletSettings}
            setOpacityWalletSettings={setOpacityWalletSettings}
          />
        </React.Fragment>
      )}

      <BidPopUp
        setOpacityBidPopUp={setOpacityBidPopUp}
        opacityBidPopUp={opacityBidPopUp}
        setCurrency={setCurrency}
        currency={currency}
      />
      <BurgerPopUp
        isActive={isActive}
        setIsActive={setIsActive}
        handleWalletClick={handleWalletClick}
        setOpacity={setOpacityWallet}
        handleWalletSettingsClick={handleWalletSettingsClick}
      />
      <ToastContainer />
    </Router>
  );
};
