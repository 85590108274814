import React from "react";
import Chat from "../../../Chat";
import Games from "../../../Games/index";
import { BackgroundgGradientView } from "./background-gradient";
import { withProvider } from "../../../../utils";

export const PageLayout = ({ children, setIsChatOpened, isChatOpened }) => {
  return (
    <React.Fragment>
      <div>
        <BackgroundgGradientView />
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            position: "relative",
          }}
        >
          <Chat setIsChatOpened={setIsChatOpened} isChatOpened={isChatOpened} />
          <div style={{ width: "100%", zIndex: 1 }}>
            {!withProvider() && <Games isChatOpened={isChatOpened} />}
            {children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
