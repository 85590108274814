import React from "react";

import rg from "../../assets/img/rg.jpg";
import trophy from "../../assets/img/Trophy.svg";
import btc from "../../assets/img/CurrencyBtc.svg";

import { fix2 } from "../../utils/fixers";
import { Text } from "../../utils/Text";
import Loader from "../Loader";
import { UserProfile } from "../UserProfile";

export const TopOfView = ({ users, timeToSummarizing }) => {
  return (
    <div className="topOf">
      <div className="topOf__container">
        <div className="topOf__title">
          <Text tid="MAIN.MAIN.TOP_OF_THE_DAY" />
        </div>
        <div>
          {!users?.length ? (
            <div className="topOf__top-is-empty">
              <Text tid="MAIN.MAIN.TOP_IS_EMPTY" />
            </div>
          ) : (
            <div className="topOf__users">
              {users[1] && (
                <div className="topOf__user silver">
                  <div className="topOf__user-avatar">
                    <UserProfile user={users[1]}>
                      <img src={users[1].photo} alt="avatar" />
                    </UserProfile>
                  </div>
                  <div className="topOf__user-trophy">
                    <img src={trophy} alt="trophy" />
                    {users[1] && users[1].totalWins}
                  </div>
                  <div className="topOf__user-value">
                    {/* <img src={btc} alt="trophy" /> */}
                    {users[1] && fix2(users[1].profit)}$
                  </div>
                </div>
              )}
              <div className="topOf__user gold">
                <div className="topOf__user-avatar">
                  <UserProfile user={users[0]}>
                    <img src={users[0].photo} alt="avatar" />
                  </UserProfile>
                </div>
                <div className="topOf__user-trophy">
                  <img src={trophy} alt="trophy" />
                  {users[0] && users[0].totalWins}
                </div>
                <div className="topOf__user-value">
                  {/* <img src={btc} alt="trophy" /> */}
                  {users[0] && fix2(users[0].profit)}$
                </div>
              </div>
              {users[2] && (
                <div className="topOf__user copper">
                  <div className="topOf__user-avatar">
                    <UserProfile user={users[2]}>
                      <img src={users[2].photo} alt="avatar" />
                    </UserProfile>
                  </div>
                  <div className="topOf__user-trophy">
                    <img src={trophy} alt="trophy" />
                    {users[2] && users[2].totalWins}
                  </div>
                  <div className="topOf__user-value">
                    {/* <img src={btc} alt="trophy" /> */}
                    {users[2] && fix2(users[2].profit)}$
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="topOf__summarizing">
          <div className="topOf__summarizing-text">
            <Text tid="MAIN.MAIN.SUMMARIZING" />:
          </div>
          {!timeToSummarizing ? (
            <Loader />
          ) : (
            <p className="topOf__summarizing-time">{timeToSummarizing}</p>
          )}
        </div>
      </div>
    </div>
  );
};
