/* eslint-disable no-param-reassign */
import moment from "moment";
import { createPortal } from "react-dom";
import React, { useEffect, useState } from "react";
import { disableScroll, enableScroll } from "../../utils/scroll";
import { axios } from "../../utils/request";
import { API } from "../../constants/api";
import { UserProfileView } from "./View";

export const UserProfile = ({ user = {}, children }) => {
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [info, setInfo] = useState(false);
  const { _id } = user;

  const handleOpen = () => {
    setLoading(true);
    setOpen(true);
    enableScroll();

    axios
      .get(API.GET_USER_PROFILE(_id))
      .then(({ data }) => {
        data.createdAt = moment(data.createdAt).format("DD.MM.YYYY");
        setLoading(false);
        setInfo(data);
      })
      .catch(() => {
        disableScroll();
        setLoading(false);
        setOpen(false);
      });
  };

  const handleClose = () => {
    disableScroll();
    setLoading(false);
    setOpen(false);
    setInfo(false);
  };

  const handleClickOutside = (e) => {
    const inc = ".user-profile";
    const out = ".user-profile-wrapper";
    if (isOpen && !e.target.closest(inc) && e.target.closest(out))
      handleClose();
  };

  useEffect(() => {
    if (isOpen) document.addEventListener("click", handleClickOutside);
    else document.removeEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isOpen]);

  useEffect(() => handleClose(), []);

  if (!isOpen || !_id || !info) {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        style: {
          cursor: "pointer",
          transition: "0.15s",
        },
        onClick: handleOpen,
      });
    });
  }

  const portal = createPortal(
    <UserProfileView
      handleClose={handleClose}
      isLoading={isLoading}
      info={info}
    />,
    document.body
  );

  return (
    <>
      {children}
      {portal}
    </>
  );
};
